@import "./utils/breakpoints.scss";
*{
    box-sizing: border-box;
}

html, body{
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    color: #333;
}

a{
    color: #333;
}

main{
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;

    &.fullView{
        max-width: 100%;
        padding: 0 3rem;
    }

    section{
        margin: 1rem 0;
        max-width: 100%;
    
        @include breakpoint(md) {
            padding: 0 100px;
            max-width: 1200px;
        }
    }
}
