    // Breakpoints

    @mixin breakpoint($class) {
        @if $class == xs {
            @media (max-width: 575px) { @content; }
        }
  
        @if $class == sm {
          @media (min-width: 576px) { @content; }
        }
  
        @else if $class == md {
          @media (min-width: 769px) { @content; }
        }
  
        @else if $class == lg {
          @media (min-width: 992px) { @content; }
        }
  
        @else if $class == xl {
          @media (min-width: 1200px) { @content; }
        }
  
        @else {
          @warn "Breakpoint mixin supports: xs, sm, md, lg, xl";
        }
      }
  
      // Use breakpoints like this:
      // aside.primary {
      //   float: right;
      //   width: 350px;
      //   @include breakpoint(sm) {
      //     float: none;
      //     width: 100%;
      //   }
      // }