@import "../../utils/breakpoints.scss";

.CheckOut {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @include breakpoint(md) {
    flex-direction: row;
  }

  &.loading {
    filter: blur(4px);
    pointer-events: none;
  }

  .loading {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
  }

  .pass {
    display: contents;
  }

  .payment {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    li {
      width: 40%;
      height: 50px;
      background-color: #eee;
      color: #eee;
      border: #ccc 1px solid;
      border-radius: 4px;
      margin: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:last-child {
        background-color: #333;
        width: calc(80% + 20px);
      }

      img {
        margin: 0;
      }
    }
  }

  .edit {
    cursor: pointer;
    max-height: 20px;
    margin-left: 1rem;
  }

  .center {
    text-align: center;
    margin: auto;

    .order {
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      @include breakpoint(md) {
        flex-direction: row;
      }

      .overview {
        padding-right: 1rem;
      }

      .payment {
        padding-left: 1rem;
      }

      ul {
        list-style-type: none;

        li {
          margin-bottom: 1rem;
          .head {
            display: block;
            font-weight: 700;
          }

          .produkt {
            margin-right: 10px;

            &::after {
              content: ",";
            }
          }
        }
      }
    }
  }

  .ShoppingCartPrice {
    .zahlung {
      margin-top: 2rem;
      background-color: #fff;
      padding: 1rem;
    }

    .deAddr {
      text-align: center;
      font-size: 0.8rem;
      padding: 2rem 1rem 0 1rem;
    }

    .Button {
      width: 100%;
    }
  }

  .checkoutStep {
    display: flex;
    flex-direction: column;
    width: 100%;

    .step {
      width: 100%;
      margin: 0 0 2rem 0;
      padding: 1rem 2rem;
      background-color: #eee;
      position: relative;
      text-align: center;
    }

    .inactive {
      .headline {
        color: #ccc;
      }
    }

    .headline {
      text-align: center;
      font-weight: 700;
      margin: 2rem 0 2rem 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .Button {
      width: 100%;
    }

    label {
      width: 100%;
      background-color: #fff;
      border: 1px solid #333;
      display: flex;
      flex-direction: column;
      text-align: left;
      padding: 0.5rem;
      font-size: 0.8rem;
      margin: 1rem 0;

      input {
        border: 0;
      }
      select {
        border: 0;
        background: transparent;
      }

      &.agb {
        border: none;
        background-color: transparent;
        width: auto;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        input {
          margin-right: 1rem;
        }
      }
    }

    .payment {
      label {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-weight: 700;

        span {
          flex-grow: 2;
        }

        img {
          max-height: 22px;
          border: 1px solid #ccc;
          border-radius: 5px;
        }
      }
    }
  }
}
