@import "../../utils/breakpoints.scss";

.Danke {
  text-align: center;
  margin: auto;

  .order {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @include breakpoint(md) {
      flex-direction: row;
    }

    .overview {
      padding-right: 0;
      @include breakpoint(md) {
        padding-right: 1rem;
      }
    }

    .payment {
      @include breakpoint(md) {
        padding-left: 1rem;
      }
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        margin-bottom: 1rem;
        .head {
          display: block;
          font-weight: 700;
        }

        .produkt {
          margin-right: 10px;

          &::after {
            content: ",";
          }
        }
      }
    }
  }
}
