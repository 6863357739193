@import "../../utils/breakpoints.scss";

.cart {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @include breakpoint(md) {
    flex-direction: row;
  }
}
.ShoppingCartPage {
  width: 100%;
  margin: 0 1rem 0 0;
  padding: 0;
  display: block;

  li {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin: 0 0 2rem 0;
    padding: 1rem 2rem;
    background-color: #eee;
    position: relative;

    .del {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 20px;
      height: auto;
      cursor: pointer;
    }

    div {
      &.details {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        height: 200px;
        width: 100%;
        padding: 1rem;

        .name {
          font-weight: 700;
        }

        .price {
          align-self: end;
        }
      }
    }

    &.header {
      font-weight: 700;
    }

    img {
      max-width: 200px;
      height: auto;
    }
  }
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .Button {
    margin-top: 2rem;
  }
}

.ShoppingCartPrice {
  width: 100%;
  margin: 0;
  padding: 1rem 2rem;
  background-color: #eee;
  position: relative;
  text-align: center;

  @include breakpoint(md) {
    margin: 0 0 0 1rem;
  }

  .headline {
    text-align: center;
    font-weight: 700;
    margin: 2rem 0 2rem 0;
  }

  .price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .Button {
    margin-top: 3rem;
    padding: 1rem 3rem;
    width: 100%;
  }
}
