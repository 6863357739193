@import "../../utils/breakpoints.scss";

.Button,
button {
  background-color: #ffffff;
  padding: 1rem;
  display: inline-block;
  color: #333333;
  text-decoration: none;
  border: 0;
  cursor: pointer;

  &.invert {
    background-color: #333333;
    color: #fff;
  }

  &.full {
    width: 100%;
    text-align: center;

    @include breakpoint(md) {
      width: auto;
    }
  }
}
