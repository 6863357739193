.Warenkorb{
    display: flex;
    justify-content: flex-end;
    flex-grow: 2;
    position: relative;

    .icon{
        position: relative;

        img{
            width: 25px;
            height: auto;
        }

        .indicator{
            color:#fff;
            background-color: #333;
            width: 16px;
            height: 16px;
            font-size: 10px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: -5px;
            left: -5px;
        }
    }


}