/* playfair-display-sc-regular - latin */
@font-face {
    font-family: 'Playfair Display SC';
    font-style: normal;
    font-weight: 400;
    src: local('Playfair Display SC Regular'), local('PlayfairDisplaySC-Regular'),
         url('../statics/fonts/playfair-display-sc-v10-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../statics/fonts/playfair-display-sc-v10-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  /* playfair-display-sc-italic - latin */
  @font-face {
    font-family: 'Playfair Display SC';
    font-style: italic;
    font-weight: 400;
    src: local('Playfair Display SC Italic'), local('PlayfairDisplaySC-Italic'),
         url('../statics/fonts/playfair-display-sc-v10-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../statics/fonts/playfair-display-sc-v10-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  /* playfair-display-sc-700 - latin */
  @font-face {
    font-family: 'Playfair Display SC';
    font-style: normal;
    font-weight: 700;
    src: local('Playfair Display SC Bold'), local('PlayfairDisplaySC-Bold'),
         url('../statics/fonts/playfair-display-sc-v10-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../statics/fonts/playfair-display-sc-v10-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  /* playfair-display-sc-700italic - latin */
  @font-face {
    font-family: 'Playfair Display SC';
    font-style: italic;
    font-weight: 700;
    src: local('Playfair Display SC Bold Italic'), local('PlayfairDisplaySC-BoldItalic'),
         url('../statics/fonts/playfair-display-sc-v10-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../statics/fonts/playfair-display-sc-v10-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  /* playfair-display-sc-900 - latin */
  @font-face {
    font-family: 'Playfair Display SC';
    font-style: normal;
    font-weight: 900;
    src: local('Playfair Display SC Black'), local('PlayfairDisplaySC-Black'),
         url('../statics/fonts/playfair-display-sc-v10-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../statics/fonts/playfair-display-sc-v10-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  /* playfair-display-sc-900italic - latin */
  @font-face {
    font-family: 'Playfair Display SC';
    font-style: italic;
    font-weight: 900;
    src: local('Playfair Display SC Black Italic'), local('PlayfairDisplaySC-BlackItalic'),
         url('../statics/fonts/playfair-display-sc-v10-latin-900italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../statics/fonts/playfair-display-sc-v10-latin-900italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  /* montserrat-300 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: local('Montserrat Light'), local('Montserrat-Light'),
         url('../statics/fonts/montserrat-v15-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../statics/fonts/montserrat-v15-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  /* montserrat-300italic - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 300;
    src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
         url('../statics/fonts/montserrat-v15-latin-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../statics/fonts/montserrat-v15-latin-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  /* montserrat-italic - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 400;
    src: local('Montserrat Italic'), local('Montserrat-Italic'),
         url('../statics/fonts/montserrat-v15-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../statics/fonts/montserrat-v15-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  /* montserrat-regular - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
         url('../statics/fonts/montserrat-v15-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../statics/fonts/montserrat-v15-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  /* montserrat-700 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
         url('../statics/fonts/montserrat-v15-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../statics/fonts/montserrat-v15-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  /* montserrat-700italic - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 700;
    src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'),
         url('../statics/fonts/montserrat-v15-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../statics/fonts/montserrat-v15-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }